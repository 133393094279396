<template>
    <base-jumbotron
            :src="src"
    >
        <v-container
                fill-height
        >
            <v-layout
                    align-center
                    justify-center
            >
                    <base-title>
                        {{$t('news.news')}}
                    </base-title>
            </v-layout>
        </v-container>
    </base-jumbotron>
</template>

<script>
    export default {
        data: () => ({
            src: './images/p1.jpg'

        })
    }
</script>
